import { ResponsiveSwarmPlot } from '@nivo/swarmplot'

const graphTheme = {
  axis: {
      ticks: {
          text:{
              fill: "wheat"
          }
      },
      legend: {
          text: {
              fill: "wheat"
          }
      }
  },
  tooltip: {
      container: {
          background: '#333',
      },
  },
}

const DashboardSwarm = ({height, data}) => {
  let max_distinct_services = Math.max(...data.map(d => d.DistinctServices))
  return <div style={{height: height}}>
        <ResponsiveSwarmPlot
            theme={graphTheme}
            data={data.map(d => ({
                ...d,
                group: d.Severity,
                id: `${d.Severity} ${d.Hostname}`,
            }))}
            groups={data.map(d => d.Severity).filter((v, i, a) => a.indexOf(v) === i)}
            value="SeverityCount"   
            size={{
              key: 'DistinctServices',
              values: [
                  1,
                  max_distinct_services
              ],
              sizes: [
                  6,
                  20
              ]
          }}         
            forceStrength={4}
            simulationIterations={100}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.6
                    ],
                    [
                        'opacity',
                        0.5
                    ]
                ]
            }}
            margin={{ top: 80, right: 100, bottom: 40, left: 100 }}
            tooltip={(node) => ( 
                <div style={{background: '#333', padding: '10px', borderRadius: '10px', color: '#fff'}}>
                    <p>{node.id}</p>
                    <p>{node.data.SeverityCount} vulnerabilities</p>
                    <p>{node.data.DistinctServices} services affected</p>
                </div>
            )}
            axisTop={{
                orient: 'top',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Vulnerabilities by severity (circle size indicates services affected on a host)",
                legendPosition: 'middle',
                legendOffset: -46,
                truncateTickAt: 0
            }}
            axisRight={{
                orient: 'right',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: '# of Vulnerabilities',
                legendPosition: 'middle',
                legendOffset: 76,
                truncateTickAt: 0
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: '# of Vulnerabilities',
                legendPosition: 'middle',
                legendOffset: -76,
                truncateTickAt: 0
            }}
        />
    </div>
}

export default DashboardSwarm