import { ResponsivePie } from '@nivo/pie'

const graphTheme = {
    axis: {
        ticks: {
            text:{
                fill: "wheat"
            }
        },
        legend: {
            text: {
                fill: "wheat"
            }
        }
    },
    tooltip: {
        container: {
            background: '#333',
        },
    },
  }
  

const DashboardPie = ({height, data}) => (
    <div style={{height: height}}>
        <ResponsivePie
            theme={graphTheme}
            data={data.map(d => ({
                id: d.Key,
                value: d.Value
            }))}            
            margin={{ top: 40, right: 20, bottom: 80, left: 20 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#fff"
            arcLinkLabelsThickness={2}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            colors={{ scheme: 'nivo' }}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 75,
                    itemHeight: 18,
                    itemOpacity: 1,
                    symbolSize: 10,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#fff'
                            }
                        }
                    ]
                }
            ]}
        />
    </div>
)

export default DashboardPie