import { ResponsiveBar } from '@nivo/bar'



const graphTheme = {
  axis: {
      ticks: {
          text:{
              fill: "wheat"
          }
      },
      legend: {
          text: {
              fill: "wheat"
          }
      }
  },
  tooltip: {
      container: {
          background: '#333',
      },
  },
}

const DashboardHorizontalStackedBar = ({height, data}) => (
      <div style={{height: height}}>
        <ResponsiveBar
            theme={graphTheme}
            data={data}
            keys={Object.keys(data[0]).filter((item) => item !== "Hostname" && item !== "Total")}
            indexBy="Hostname"
            margin={{ top: 50, right: 200, bottom: 50, left: 150 }}
            padding={0.5}   
            colors={{ scheme: 'nivo' }}
            valueScale={{
                type: 'symlog'
              }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -30,
            }}
            axisBottom={{
                tickSize: 3,
                tickPadding: 5,
                tickRotation: -45,
            }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    itemTextColor: 'wheat',
                    justify: false,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    translateX: 0,                    
                    translateY: 50,
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            layout="horizontal" 
            enableGridY={false} 
            enableGridX={true}
        />
    </div>
)

export default DashboardHorizontalStackedBar