import { EuiButton, EuiButtonIcon, EuiCommentList, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui';
import React, { useEffect, useState } from 'react'
import { Responsive, WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import Axios from 'axios';
import Api from '../../Api';

import Swarm from './DashboardSwarm';
import Pie from './DashboardPie';
import HorizontalStackedBar from './DashboardHorizontalStackedBar';
import AreaBump from './DashboardAreaBump';

const ResponsiveGridLayout = WidthProvider(Responsive);


const LandingDashboard = () => {

    const [layout, changeLayout] = useState()
    const [keyMap, changeKeyMap] = useState()
    const [showFlyout, setShowFlyout] = useState(false)

    const handleAddWidget = (widgetName) => {        
        changeLayout([
            ...layout,
            { i: widgetName, x: 0, y: 0, w: 2, h: 1 }
        ])
    }

    const WidgetTypes = [
        "Vulnerabilities Over Time",
        "Vulnerabilities & Affected Assets",        
        "Top Riskiest Assets",
        "Pending CVEs",
        "Pending Hardening",
        "Pending Updates",
        "Assets By Type"
    ]

    useEffect(() => {
        layout && layout.forEach( (item) => {
        Axios.post(Api.retrieveDashboardData, {
            Widget: item.i
        }).then(resp => {
            let data = resp.data
            let chartContent = null
            
            switch(item.i){
                case "Pending Hardening":                    
                    chartContent = <Pie height={(150*item.h)-50} data={data}/>
                    break;
                case "Pending CVEs":                    
                    chartContent = <Pie height={(150*item.h)-50} data={data}/>
                    break;
                case "Pending Updates":                    
                    chartContent = <Pie height={(150*item.h)-50} data={data}/>
                    break;
                case "Assets By Type":                    
                    chartContent = <Pie height={(150*item.h)-50} data={data}/>
                    break;
                case "Vulnerabilities & Affected Assets":
                    chartContent = <Swarm height={(150*item.h)-50} data={data}/>
                    break;
                case "Top Riskiest Assets":
                    chartContent = <HorizontalStackedBar height={(150*item.h)-50} data={data} />
                    break;
                case "Vulnerabilities Over Time":
                    chartContent = <AreaBump height={(150*item.h)-50}  data={data} />
                    break;
                default:
                    break;
            }

            changeKeyMap(prevKeyMap => ({
                ...prevKeyMap,
                [item.i]: <div style={{
                    maxHeight: 150*item.h,
                    height: 150*item.h,
                    overflow: "auto"
                }}>
                    <div 
                        onMouseDown={e => e.stopPropagation()}
                        onTouchStart={e => e.stopPropagation()}
                        onClick={(e) => {
                            e.stopPropagation()
                            if (window.confirm("Are you sure you want to delete this widget?")) {
                                changeLayout(layout.filter((layoutItem) => layoutItem.i !== item.i))
                            }                        
                        }}
                    >
                        <EuiButtonIcon
                            display="base"
                            iconType="minusInCircleFilled"
                            aria-label="Delete"
                            size='xs'
                            style={{                    
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                background: "transparent",
                                border: "none",                        
                                color: "wheat",
                                fontSize: "18px",
                                fontWeight: "bold",
                                cursor: "pointer",
                                zIndex: 100
                            }}
                        />
                    </div>                
                    <EuiTitle size="s">
                        <h3>{item.i}</h3>
                    </EuiTitle>
                    {chartContent}
                    
                </div>
            }))                    
        }).catch(err => console.log(err))            
        })

    }, [layout])

    useEffect(() => {

        Axios.get(Api.retrieveDashboard).then(resp => {
            if(resp.data && resp.data.Layout){
                changeLayout(JSON.parse(resp.data.Layout))
                return
            }
            changeLayout([
                { i: "Vulnerabilities Over Time", x: 0, y: 0, w: 6, h: 3 },
                { i: "Vulnerabilities & Affected Assets", x: 6, y: 0, w: 6, h: 3 },
                { i: "Assets By Type", x: 0, y: 3, w: 3, h: 3 },
                { i: "Top Riskiest Assets", x: 3, y: 3, w: 9, h: 3 },
            ])
        }).catch(err => console.log(err))
    }, [])

    return (
        <>
            {layout && keyMap && <ResponsiveGridLayout
            className="layout"
            layouts={{
                lg: layout
            }}
            onLayoutChange={(layout) => {
                changeLayout(layout)
            }}
            breakpoints={{ lg: 1200 }}
            cols={{ lg: 12}}
            rowHeight={150}            
        >
            {layout.map((item) => (
                <div key={item.i}>{keyMap[item.i]}</div>
            ))}            
        </ResponsiveGridLayout>} 
        <EuiSpacer />      
        <EuiButton fill color='primary' onClick={() => setShowFlyout(true)} iconType="plusInCircle">
            Add Widget
        </EuiButton> 
        <EuiButton fill color='primary' onClick={() => 
            Axios.post(Api.updateDashboard, {Layout: JSON.stringify(layout)}).then(resp => alert("Dashboard Layout Saved")).catch(err => alert("Could not save dashboard layout"))
        } iconType="save" style={{marginLeft: "10px"}}>
            Save Dashboard Layout
        </EuiButton>
        {
            showFlyout && <EuiFlyout
                onClose={() => setShowFlyout(false)}
                aria-labelledby="flyoutTitle"
            >
                <EuiFlyoutHeader hasBorder>
                    <EuiTitle size="m">
                        <h2 id="flyoutTitle">Add Widget</h2>
                    </EuiTitle>
                </EuiFlyoutHeader>

                <EuiFlyoutBody>
                    <EuiText>
                        <EuiCommentList comments={WidgetTypes.map(item => ({
                            "username": item,
                            timelineIcon: "createAdvancedJob",
                            style: { cursor: "pointer" },
                            onClick: () => handleAddWidget(item)
                        }))} />
                    </EuiText>
                </EuiFlyoutBody>

                <EuiFlyoutFooter>
                    <EuiButton onClick={() => {                         
                        setShowFlyout(false)
                    }}>
                        Close
                    </EuiButton>
                </EuiFlyoutFooter>
            </EuiFlyout>
        }        
        </>        
    )
}

export default LandingDashboard