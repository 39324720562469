import { ResponsiveAreaBump } from '@nivo/bump'



const graphTheme = {
  axis: {
      ticks: {
          text:{
              fill: "wheat"
          }
      },
      legend: {
          text: {
              fill: "wheat"
          }
      }
  },
  tooltip: {
      container: {
          background: '#333',
      },
  },
}

const DashboardAreaBump = ({height, data}) => {

  let Severity_Done = new Set()
  let Timestamp_Done = new Set()
  let graph_data = {}

  data.forEach(datum => {
    if (!Severity_Done.has(datum.Severity)){
      Severity_Done.add(datum.Severity)
      graph_data[datum.Severity] = {
        "id": datum.Severity,
        "data": []
      }      
    }
    if (!Timestamp_Done.has(datum.Timestamp)){
      Timestamp_Done.add(datum.Timestamp)
    }

    graph_data[datum.Severity].data.push({"x": datum.Timestamp, "y": datum.Count})
  })

  Severity_Done.forEach(severity => {
    Timestamp_Done.forEach(timestamp => {
      if (!graph_data[severity].data.some(datum => datum.x === timestamp)){
        graph_data[severity].data.push({"x": timestamp, "y": 0})
      }
    })
    graph_data[severity].data.sort((a, b) => {
      return new Date(a.x) - new Date(b.x)
    })
  })

      

    return <div style={{height: height}}>
        <ResponsiveAreaBump
            theme={graphTheme}
            data={Object.keys(graph_data).map(key => graph_data[key])}
            margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
            spacing={8}
            colors={(data) => {
              switch(data.id){
                case "INFO":
                  return "#3c7efa"
                case "LOW":
                  return "#fad73c"
                case "MEDIUM":
                  return "#fa823c"
                case "HIGH":
                  return "#f55a42"
                case "CRITICAL":
                  return "#fa3c3c"
                default:
                  return "#000"
              }
            }}
            blendMode="multiply"            
            startLabel="id"
            endLabel="id"
            axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -36,
                truncateTickAt: 0
            }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32,
                truncateTickAt: 0
            }}
        />
    </div>
}

export default DashboardAreaBump