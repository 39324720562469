import React, { Fragment, useState, useReducer, Suspense, useEffect } from 'react';
import '@elastic/eui/dist/eui_theme_dark.css';

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFocusTrap,
  EuiHeader,
  EuiHeaderBreadcrumbs,
  EuiHeaderLogo,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiPage,
  EuiPageBody,
  EuiText,
} from '@elastic/eui';
import Discover from './views/Discover';
import Login from './views/Login';
import Axios from 'axios';
import {ViewContext, UserContext} from './Context';
import Api from './Api';
import LoadingModal from './views/CommonWidgets/LoadingModal';
import Drawer from './views/MenuWidgets/Drawer';
import "./App.css";
import DisabledUser from './views/DisabledUser';
import IconMap from './Utils';
import LandingPage from './views/LandingPage';


Axios.defaults.withCredentials = true

const currentViewReducer = (state, action) => {
  
  console.log("View ID:", action.viewName)
 


  switch (action.viewName) {
    case "LANDING":
      return {
        ...state,
        viewID: action.viewName,
        viewName: <LandingPage />
      }
    case "DISCOVER":
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Discover />
      }
    case "COMPLIANCE":
        return {
          ...state,
          viewID: action.viewName,
          viewName: <Discover selectedTab="compliance--id" />
        }
    case "ASSETS":
      const Assets = React.lazy(()=> import('./views/Assets') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <Assets />
        </Suspense>
      }
    case "XEV":
      const XEV = React.lazy(()=> import('./views/Xev') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <XEV />
        </Suspense>
      }
    case "FLASHLIGHT":
        const Flashlight = React.lazy(()=> import('./views/Flashlight') );
        return {
          ...state,
          viewID: action.viewName,
          viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
            <Flashlight />
          </Suspense>
        }
    case "THREAT_FEED":
      const ThreatFeed = React.lazy(()=> import('./views/PersonalThreatFeed') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <ThreatFeed />
        </Suspense>
      }
    case "AGENTS":
      const Agents = React.lazy(()=> import('./views/Agents') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <Agents />
        </Suspense>
      }
    case "DOMAINS":
      const Domains = React.lazy(()=> import('./views/Domains') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <Domains />
        </Suspense>
      }
    case "DOMAIN_PHISH_MONITOR":
      const DomainPhishMonitor = React.lazy(()=> import('./views/DomainPhishMonitor') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <DomainPhishMonitor />
        </Suspense>
      }
    case "URL_SCAN":
      const UrlScan = React.lazy(()=> import('./views/UrlScan') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <UrlScan />
        </Suspense>
      }
    case "HOST_GROUPS":
      const HostGroups = React.lazy(()=> import('./views/HostGroups') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <HostGroups />
        </Suspense>
      }
    case "SCANS":
      const Scans = React.lazy(()=> import('./views/Scans') );
      return {
        ...state,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <Scans />
        </Suspense>        
      }
    case "PDF_REPORT":
      const PdfReportV2 = React.lazy(()=> import('./views/PdfReportV2') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <PdfReportV2 />
        </Suspense>
      }
    case "ACCOUNT_SETTINGS":
      const AccountSettings = React.lazy(()=> import('./views/AccountSettings') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <AccountSettings />
        </Suspense>
      }
    case "SETUP_WIZARD":
      const SetupWizard = React.lazy(()=> import('./views/Wizard.js') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <SetupWizard />
        </Suspense>
      }
    case "OSQUERY_EDITOR":
        const OsqueryEditor = React.lazy(()=> import('./views/OsqueryEditor.js') );
        return {
          ...state,
          viewID: action.viewName,
          viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
            <OsqueryEditor />
          </Suspense>
        }
    case "HOST_FORENSICS_DASH":
      const HostForensicsDash = React.lazy(()=> import('./views/OsQueryWidgets/HostForensicDash') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <HostForensicsDash />
        </Suspense>
      }
    case "HOST_FORENSICS_IDS_DASH":
      const HostForensicsIdsDash = React.lazy(()=> import('./views/OsQueryWidgets/HostForensicIdsDash') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <HostForensicsIdsDash />
        </Suspense>
      }    
    case "ADMIN_DASH":
      const AdminDash = React.lazy(()=> import('./views/AdminDashboard') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <AdminDash />
        </Suspense>
      }
    case "RENEWAL":
        const Renewal = React.lazy(()=> import('./views/Renewal') );
        return {
          ...state,
          viewID: action.viewName,
          viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
            <Renewal/>
          </Suspense>
        }
    case "SIGNUP":
        const Signup = React.lazy(()=> import('./views/SignUp') );
        return {
          ...state,
          viewID: action.viewName,
          viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
            <Signup/>
          </Suspense>
        }
    case "FORGOT-PASSWORD":
      const ForgotPassword = React.lazy(()=> import('./views/ForgotPassword') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <ForgotPassword/>
        </Suspense>
      }
    case "RESET-PASSWORD":
        const ResetPassword = React.lazy(()=> import('./views/ResetPassword') );
        return {
          ...state,
          viewID: action.viewName,
          viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
            <ResetPassword/>
          </Suspense>
        }
    case "PAYMENTS":
      const Payments = React.lazy(()=> import('./views/PaymentWidgets/Payments') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <Payments />
        </Suspense>
      }    
    case "SUBDOMAINS":
      const Subdomains = React.lazy(()=> import('./views/SubDomains') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <Subdomains />
        </Suspense>
      }
      case "ENTITY-DASHBOARD":
        const EntityDashboard = React.lazy(()=> import('./views/EntityDash.js') );
        return {
          ...state,
          viewID: action.viewName,
          viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
            <EntityDashboard />
          </Suspense>
        } 
    case "WEB-SCANNER-DASHBOARD":
      const WebScannerDashboard = React.lazy(()=> import('./views/WebScanDash') );
      return {
        ...state,
        viewID: action.viewName,
        viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
          <WebScannerDashboard />
        </Suspense>
      }    
    case "PORTA_PROFILES":
        const PortaProfiles = React.lazy(()=> import('./views/Porta/PortaProfiles') );
        return {
          ...state,
          viewID: action.viewName,
          viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
            <PortaProfiles />
          </Suspense>
      }   
    case "PORTA_REGEXES":
        const PortaRegexes = React.lazy(()=> import('./views/Porta/PortaRegexes') );
        return {
          ...state,
          viewID: action.viewName,
          viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
            <PortaRegexes />
          </Suspense>
        } 
    case "PATCH_MANAGEMENT":
        const PatchManagement = React.lazy(()=> import('./views/Pmac/PatchManagement') );
        return {
          ...state,
          viewID: action.viewName,
          viewName: <Suspense fallback={<LoadingModal isLoading={true} />}>
            <PatchManagement />
          </Suspense>
        }
    default:
      return {
        ...state,
        viewID: "LOGIN",
        viewName: <Login handleSuccessfulLogin={state.handleSuccessfulLogin}/>
      }      
  }

}

const App = () => {
  
  const [isAdmin, changeIsAdmin] = useState(false)
  const [currentUser, changeCurrentUser] = useState(window.localStorage.getItem("currentUser") ? window.localStorage.getItem("currentUser") : "user")
  const [isDisabled, changeIsDisabled] = useState(false)
  const [menuMaxWidth, setMenuMaxWidth] = useState("5vw")
    

  const [currentViewState, changeCurrentViewState] = useReducer(currentViewReducer, {
    handleSuccessfulLogin: (user) => {
      changeCurrentUser(user)
      window.localStorage.setItem("currentUser", user)
      changeCurrentViewState({
        viewName: "LANDING"
      })
    }
  })

  const [breadcrumbs, changeBreadcrumbs] = useState([      
    {
      text: 'CyberSift Tutela',
      href: `${window.location.protocol}//${window.location.host}`,
    },
  ]);  

  useEffect(()=>{    
    
    
    let cssLink = document.createElement("link")
    cssLink.setAttribute("id", "ls-theme")
    cssLink.setAttribute("rel", "stylesheet")
    
    cssLink.setAttribute("href", "css/eui_theme_dark.css")    
    document.querySelector("head").appendChild(cssLink)
  
}, []) 

  useEffect(()=>{
   
    const hashMap = {
      "#signup": "SIGNUP",
      "#signup-micro": "SIGNUP",
      "#signup-small": "SIGNUP",
      "#signup-medium": "SIGNUP",
      "#signup-large": "SIGNUP",
      "#forgotPassword": "RESET-PASSWORD",
      undefined: "LOGIN"
    }
    
    const viewName = hashMap[window.location.hash]
    console.log(viewName)
    changeCurrentViewState({
      viewName: viewName
    })


  }, [])

  
  const renderLogo = () => {
    const logo = IconMap[window.location.hostname] || IconMap["localhost"]
    return <EuiHeaderLogo
      iconType={logo}
      href={`${window.location.protocol}//${window.location.host}`}
      aria-label="Home"
    />
  };

  const renderBreadcrumbs = () => {
    return <EuiHeaderBreadcrumbs breadcrumbs={breadcrumbs} />;
  };
  

  useEffect(()=> {    
    if (currentViewState.viewID &&
        currentViewState.viewID !=="LOGIN" && 
        currentViewState.viewID !=="FORGOT-PASSWORD" &&
        currentViewState.viewID !=="RESET-PASSWORD" &&
        currentViewState.viewID !=="PAYMENTS" &&
        currentViewState.viewID !=="SIGNUP"){      

      Axios.post(Api.checkLogin)
          .then(resp => {
              if (resp.status !== 200) {
                  changeCurrentViewState({
                      viewName: "LOGIN"
                  }) 
              } else if (!resp.data.enabled){
                changeIsDisabled(true)
              }
          }).catch(e =>  changeCurrentViewState({
              viewName: "LOGIN"
          }))
    }      

  }, [currentViewState.viewID])


  return (
    <Fragment>
      <EuiFocusTrap>
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            overflowX: "hidden",
            overflowY: "auto"
          }}>
          <EuiHeader position="fixed">
            <EuiHeaderSection grow={false}>
              <EuiHeaderSectionItem border="right">
                {renderLogo()}
              </EuiHeaderSectionItem>
            </EuiHeaderSection>

            {renderBreadcrumbs()}

            <EuiHeaderSection side="right">
              
              <EuiHeaderSectionItem>
                {currentViewState.viewID !== "LOGIN" && currentViewState.viewID !== "SIGNUP" ?
                  <>
                    <EuiText style={{marginLeft: 10}}>
                      Welcome, {currentUser}
                    </EuiText>
                    <EuiHeaderSectionItemButton aria-label="Logout">
                      <EuiButton size="s" style={{
                          margin:5
                        }} 
                        color='primary' 
                        onClick={()=>{
                          Axios.post(Api.logout).then(_=>changeCurrentViewState({
                            viewName: "LOGIN"
                          }))
                        }}
                      >
                        Logout
                      </EuiButton>
                    </EuiHeaderSectionItemButton>
                  </>
                  
                :
                  null
                }
              </EuiHeaderSectionItem>
            </EuiHeaderSection>
          </EuiHeader>

          <ViewContext.Provider value={{
            changeView: changeCurrentViewState
          }}>
            <UserContext.Provider value={{
              isAdminUser: isAdmin,
              changeIsAdminUser: changeIsAdmin
            }}>
              <EuiFlexGroup direction="row" style={{marginTop: 30}}>
                <EuiFlexItem grow={false} 
                  style={{maxWidth: menuMaxWidth, marginTop: 35, marginLeft: 25, transition: "max-width 1s"}} 
                  onMouseLeave={()=>setMenuMaxWidth("5vw")} 
                  onMouseEnter={()=>setMenuMaxWidth("20vw")}
                >
                  <Drawer isAdmin={isAdmin} isVisible={currentViewState.viewID!=="LOGIN" && currentViewState.viewID!=="SIGNUP"}/>                                                      
                </EuiFlexItem>
                <EuiFlexItem grow={true} >
                  <EuiPage className="euiNavDrawerPage" style={{maxWidth: "90vw"}}>
                    <EuiPageBody className="euiNavDrawerPage__pageBody">                
                        {currentViewState.viewName}          
                    </EuiPageBody>
                  </EuiPage>
                </EuiFlexItem>                          
              </EuiFlexGroup>              
            </UserContext.Provider>    
          </ViewContext.Provider>
                    
        </div>
      {isDisabled ? <DisabledUser />: null}
      </EuiFocusTrap>
    </Fragment>
  );
};

export default App