import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
    EuiButton,
    EuiCard,
    EuiCodeBlock,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageContentHeader,
    EuiPageContentHeaderSection,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiSpacer,
    EuiSwitch,
    EuiTab,
    EuiTabs,
    EuiText,
    EuiTitle,
  } from '@elastic/eui';
import Axios from 'axios';
import Api from '../Api';
import VulnByPort from './DiscoverWidgets/VulnByPort';
import VulnBySeverity from './DiscoverWidgets/VulnBySeverity';
import VulnTable from './DiscoverWidgets/VulnTable';
import FilterBar from './CommonWidgets/FilterBar';
import VulnByAddress from './DiscoverWidgets/VulnByAddress';
import ResolvedVsPending from './DiscoverWidgets/ResolvedVsPending';
import ScanSelect from './DiscoverWidgets/ScanSelect';
import HostGroupSelect from './DiscoverWidgets/HostGroupSelect';
import LoadingModal from './CommonWidgets/LoadingModal';
import {ViewContext} from '../Context';
import VulnByExploit from './DiscoverWidgets/VulnByExploit';
import VulnByDetectionType from './DiscoverWidgets/VulnByDetectionType';
import Compliance from './Compliance';
import ExportAsCsvServerSide from './CommonWidgets/ExportAsCsvServerSide';
import Library from './Library';
import EntityDash from './EntityDash';

const chartHeight = window.innerWidth/6 //"350px"

const DEFAULT_FILTERS = {
    portFilter: false,
    addressFilter: false,
    tutelaseverityFilter: false,
    severityFilter: false,
    resolutionFilter: false,
    exploitFilter: false,
    scanFilter: false,
    hostgroupFilter: false,
    searchQuery: false,
    detectionType: false,
    cveFilter: false,
    versionFilter: false,
    showLatest: false,
    tagFilter: false,
    endDateFilter: 0,
    startDateFilter: 0,
}

const hasFilters = (currentFilters) =>{
    let result = false
    Object.values(currentFilters).forEach(val=> {
        if(val !== false){
            result=true
        }
    })
    return result
}

const graphTheme = {
    axis: {
        ticks: {
            text:{
                fill: "wheat"
            }
        },
        legend: {
            text: {
                fill: "wheat"
            }
        }
    },
    tooltip: {
        container: {
            background: '#333',
        },
    },
}

const Discover = ({ selectedTab = "network-host--id" }) => {

    const [initialSearchData, changeInitialSearchData] = useState({})
    const [ByTutelaSeverityData, changeByTutelaSeverityData] = useState([])
    const [BySeverityData, changeBySeverityData] = useState([])
    const [ByPortData, changeByPortData] = useState([])
    const [ByResolutionData, changeByResolutionData] = useState([])
    const [ByExploitData, changeByExploitData] = useState([])
    const [ByDetectionTypeData, changeByDetectionTypeData] = useState([])
    const [ByAddressData, changeByAddressData] = useState([])
    const [ByVersionData, changeByVersionData] = useState([])
    const [TableData, changeTableData] = useState([])
    const [discoveryData, changeDiscoveryData] = useState([])
    const [scanData, changeScanData] = useState([])
    const [hostGroupData, changeHostGroupData] = useState([])
    const [filters, changeFilters] = useState(DEFAULT_FILTERS)
    const [sortField, changeSortField] = useState("Address")
    const [sortDirection, changeSortDirection] = useState("asc")
    const [isLoading, changeIsLoading] = useState(true)
    const [pageNumber, changePageNumber] = useState(0)
    const [pageSize, changePageSize] = useState(50)
    const [totalItemCount, changeTotalItemCount] = useState(0)
    const [selectedTabId, changeSelectedTabId] = useState(selectedTab)
    const {changeView} = useContext(ViewContext)    

    const getPageContent = (isLoading, totalItemCount, hasFilters, selectedTabId) => {

        if (isLoading){
            return <LoadingModal isLoading={isLoading} />
        }

        if (selectedTabId==="entity--id"){
            return <EntityDash />
        }

        if (selectedTabId==="webscan--id"){
            changeView({
                viewName: "WEB-SCANNER-DASHBOARD"
            })
            return
        }

        if (selectedTabId==="compliance--id"){
            return <Compliance />
        }

        if (selectedTabId==="library--id"){
            return <Library />
        }


        if (totalItemCount === 0 && !hasFilters){
            return <>                       
                    <EuiFlexGroup gutterSize="l">
                        <EuiFlexItem>
                            <EuiCard
                                icon={<EuiIcon size="xxl" type="savedObjectsApp" />}
                                title="No Scan Data Available"
                                description="Looks like you haven't run any scans yet. Download the agent and run it at least once to see results here."
                                footer={
                                <div>
                                        <EuiButton onClick={()=>changeView({
                                            viewName: "SETUP_WIZARD"
                                        })}>
                                            Setup Wizard
                                        </EuiButton>      
                                        <EuiButton style={{marginLeft: 5}} onClick={()=>window.open("https://docs.tutela.cybersift.io", '_blank')}>
                                            Documentation and FAQs
                                        </EuiButton>                                         
                                    <EuiSpacer size="xs" />   
                                    <EuiText size="s">
                                        <p>
                                            Or pull the agent from docker:
                                            <EuiCodeBlock
                                                language="bash"
                                                fontSize="m"
                                                paddingSize="m"
                                                isCopyable>
                                                    {"docker pull dvassallocs/tutela"}
                                            </EuiCodeBlock>                                                     
                                        </p>                                                
                                    </EuiText>                                         
                                </div>
                                }
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>                    
            </>
        }

        if (totalItemCount === 0){
            return <>                
                <EuiFlexGroup gutterSize="l">
                    <EuiFlexItem>
                        <EuiCard
                            icon={<EuiIcon size="xxl" type="savedObjectsApp" />}
                            title="No Vulnerabilies Detected in Uploaded Scans"
                            description="Scans have been successfully uploaded, but it looks like there are no vulnerabilities detected in the uploaded data."
                            footer={ 
                                <EuiText size="s">
                                    <p>
                                        This is a good thing... no vulnerabilities!
                                    </p>                                                
                                </EuiText>                                         
                            }
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>                
            </>
        }

        return <>
                <EuiPageContentHeader>
                    <EuiPageContentHeaderSection>
                        <EuiTitle>
                            <h2>Dashboard</h2>
                        </EuiTitle>
                    </EuiPageContentHeaderSection>
                    <EuiPageHeaderSection>
                        { 
                            filters.scanFilter && filters.scanFilter !== "false" ? 
                                <span
                                    style={{paddingRight: 10}}
                                >                            
                                    <EuiSwitch                            
                                        label="Show only CVEs unique/new to this scan"
                                        checked={filters.showLatest}
                                        onChange={ e => changeFilters({
                                            ...filters,
                                            showLatest: e.target.checked
                                        })}
                                    />
                                </span> 
                            : null
                        }
                                                
                        <ScanSelect scanData={scanData} onScanChanged={scanName => changeFilters({
                            ...filters,
                            scanFilter: scanName
                        })}/>
                        <HostGroupSelect hostGroupData={hostGroupData} onHostGroupChanged={hostGroup => changeFilters({
                            ...filters,
                            hostgroupFilter: hostGroup
                        })}/>
                    </EuiPageHeaderSection>
                </EuiPageContentHeader>
                <EuiPageContentBody> 
                <h3>Vulnerabilities by Severity</h3>
                <div style={{height: "200px"}}>
                    <VulnBySeverity data={BySeverityData} theme={graphTheme} clickHandler={(e) => {
                        changeFilters({
                        ...filters,
                        severityFilter: e.indexValue
                    })}}/>
                </div>
                <EuiSpacer />
                <EuiFlexGroup direction="row" alignItems="center">
                    <EuiFlexItem>
                        <h3>Top 20 Vulnerabilities by Host</h3>
                        <div style={{height: chartHeight}}>
                            <VulnByAddress height={chartHeight} data={ByAddressData} theme={graphTheme} clickHandler={(e) => changeFilters({
                                ...filters,
                                addressFilter: e.id
                            })}/>
                        </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <h3>Top 20 Vulnerabilities by Port/Software</h3>
                        <div style={{height: chartHeight}}>
                            <VulnByPort height={chartHeight} data={ByPortData} theme={graphTheme} clickHandler={(e) => changeFilters({
                                ...filters,
                                portFilter: e.id
                            })}/>
                        </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <h3>Vulnerabilities by Tutela Severity</h3>                        
                        <div style={{height: chartHeight}}>
                            <VulnByExploit height={chartHeight} data={ByTutelaSeverityData} theme={graphTheme} clickHandler={(e) => changeFilters({
                                ...filters,
                                tutelaseverityFilter: e.id
                            })}/>
                        </div> 
                        
                        {/* <h3> Exploit Available</h3>
                        <div style={{height: chartHeight}}>
                            <VulnByExploit height={chartHeight} data={ByExploitData} theme={graphTheme} clickHandler={(e) => changeFilters({
                                ...filters,
                                exploitFilter: e.id
                            })}/>
                        </div> */}
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <h3> Entity Types </h3>
                        <div style={{height: chartHeight}}>
                            <VulnByDetectionType height={chartHeight} data={ByDetectionTypeData} theme={graphTheme} clickHandler={(e) => changeFilters({
                                ...filters,
                                detectionType: e.id
                            })}/>
                        </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <h3> Resolved vs Pending</h3>
                        <div style={{height: chartHeight}}>
                            <ResolvedVsPending height={chartHeight} data={ByResolutionData} theme={graphTheme} clickHandler={(e) => changeFilters({
                                ...filters,
                                resolutionFilter: e.id
                            })}/>
                        </div>
                    </EuiFlexItem>
                </EuiFlexGroup>    
                <EuiSpacer />

                <VulnTable 
                    data={TableData} 
                    searchFilterHandler={changeEntries=>{

                        let changedFilters = {}

                        changeEntries.forEach(entry => {
                            let id = entry[0]
                            let filterType = entry[1]
                            let selectedFilters = typeof(id) === "object" ? id.join(",") : id
                            changedFilters[filterType] = selectedFilters
                        })
                        
                        changeFilters({
                            ...filters,
                            ...changedFilters
                        })
                    }}                        
                    searchFilterData={initialSearchData}                                                
                    initialSearch={filters.searchQuery}
                    pageIndex={pageNumber} 
                    pageSize={pageSize}
                    totalItemCount={totalItemCount} 
                    setPageIndex={changePageNumber} 
                    setPageSize={changePageSize}
                    handleSearchChange={handleSearchChange}
                    
                    sortInfo={{
                        sortField: sortField,
                        sortDirection: sortDirection,
                        handleSortChange: handleSortChange
                    }}
                    rehydrateData={()=>{
                        console.log("rehydrating data...")
                        getDiscoveryData()
                    }}
                    currentFilters={filters}
                />
                    
                <ExportAsCsvServerSide dataView="VULN" filters={filters}/>
                
                <EuiSpacer />
            </EuiPageContentBody> 
        </>   
    }
    

    const handleSearchChange = ({queryText}) => {
        console.log(queryText)
        changeFilters({
            ...filters,
            searchQuery: queryText
        })
    }

    const handleSortChange = (sortField, sortDirection)=>{
        changeSortDirection(sortDirection)
        changeSortField(sortField)
    }

    const populateData = () => {

        const tableData=[]                    
       
        discoveryData.forEach(cve => {            
            
            if (cve.TutelaScore == 0){
                cve.TutelaScore = (cve.Cve.Score/10) * (cve.Cve.Epss) * ((cve.ExploitDb=="" ? 1 : 2)) * 100
            }

            tableData.push({
                id: cve.ID,//`${cve.Address}:${cve.CveID}:${cve.Port}:${cve.Service.Product}`,
                Address: cve.Address,
                Hostname: cve.Hostname,
                Port: cve.Port,
                Service: cve.Service.Name,
                Product: cve.Package.Name === "" ? cve.Service.Product : cve.Package.Name,
                Version: cve.Package.Version === "" ? cve.Service.Version : cve.Package.Version,
                Severity: cve.Cve.Severity === "" ? "INFO" : cve.Cve.Severity,
                CveDetails: {
                    Details: cve.Details ? cve.Details : "",
                    ...cve.Cve
                },
                ServiceDetails: cve.Service,
                CveID: cve.CveID,
                CS_Score: {
                    exploitdb: cve.ExploitDb,
                    cve_score: cve.Cve.Score,
                    epss: cve.Cve.Epss,
                    score: cve.TutelaScore,
                    label: cve.TutelaSeverity
                },
                EntityType: cve.Host.EntityType,
                Resolved: cve.Resolved,
                CveDescription: cve.Cve.Description,
                Notes: cve.Notes,
                ScanCounter: cve.ScanCounter,
                Tags: cve.Tags
            })

        });

        changeTableData(tableData)
            
    }

    useEffect(()=>{        
        populateData()
    },[discoveryData])


    useEffect(()=>{
        Axios.get(Api.retrieveScans).then(resp => {
            changeScanData(resp.data)            
        })
        Axios.get(Api.retrieveHostGroups).then(resp => {
            changeHostGroupData(resp.data)        
        })   
    }, [])
    
    useEffect(()=>changePageNumber(0),[filters])
 
    const getDiscoveryData = () => {
        changeIsLoading(true)

        Axios.post(Api.retrieveCVEs_V2+`/${pageNumber}`, {
            Scan: filters.scanFilter.toString(),
            Port: filters.portFilter.toString(),
            Host: filters.addressFilter.toString(),
            Severity: filters.severityFilter.toString(),
            TutelaSeverity: filters.tutelaseverityFilter.toString(),
            Status: filters.resolutionFilter.toString(),
            SearchQuery: filters.searchQuery.toString(),
            HostGroup: filters.hostgroupFilter.toString(),
            Exploitable: filters.exploitFilter.toString(),
            DetectionType: filters.detectionType.toString(),
            Tags: filters.tagFilter.toString(),
            Version: filters.versionFilter.toString(),
            Cve: filters.cveFilter.toString(),
            SortDirection: sortDirection,
            SortField: sortField,
            ShowLatest: filters.showLatest,
            StartDateFilter: filters.startDateFilter,
            EndDateFilter: filters.endDateFilter,
            EntityTypeFilter: filters.entitytypeFilter,
            PageSize: pageSize
        }).then(resp => {                        
            const bySeverityData=[]
            Object.entries(resp.data.Severity).forEach(([key, val]) => bySeverityData.push({
                id: key === "" ? "INFO" : key,
                value: val
            }))

            const byTutelaSeverityData=[]
            Object.entries(resp.data.TutelaSeverity).forEach(([key, val]) => byTutelaSeverityData.push({
                id: key === "" ? "INFO" : key,
                value: val
            }))

            const byDetectionTypeData=[]
            Object.entries(resp.data.DetectionType).forEach(([key, val]) => byDetectionTypeData.push({
                id: key,
                value: val
            }))

            let byPortData=[]            
            Object.entries(resp.data.Port).forEach(([key, val]) => {                
                byPortData.push({
                    id: key,
                    label: key,
                    value: val
                })                
            })

            let byAddressData=[]
            Object.entries(resp.data.Host).forEach(([key, val]) => byAddressData.push({
                id: key,
                label: key,
                value: val
            }))

            let byVersionData=[]
            Object.entries(resp.data.Version).forEach(([key, val]) => byVersionData.push({
                id: key,
                label: key,
                value: val
            }))


            const byResolutionData=[]
            Object.entries(resp.data.Status).forEach(([key, val]) => byResolutionData.push({
                id: key,
                label: key,
                value: val
            }))            
            

            const byExploitData=[]
            Object.entries(resp.data.Exploitable).forEach(([key, val]) => byExploitData.push({
                id: key,
                label: key,
                value: val
            }))

            let byCveNameData=[]
            Object.entries(resp.data.CveIDs).forEach(([key, val]) => byCveNameData.push({
                id: key,
                label: key,
                value: val
            }))

            const byTagData=[{
                id: "untagged",
                label: "untagged",
                value: "untagged"
            }]
            Object.entries(resp.data.Tags).filter(([key, val]) => key!=="").forEach(([key, val]) => byTagData.push({
                id: key,
                label: key,
                value: key
            }))

            byPortData = byPortData.sort((a,b)=>b.value - a.value).slice(0, 20)
            byAddressData = byAddressData.sort((a,b)=>b.value - a.value).slice(0, 20)
            byVersionData = byVersionData.sort((a,b)=>b.value - a.value).slice(0, 20)

            if (pageNumber===0){
                changeBySeverityData(bySeverityData)
                changeByTutelaSeverityData(byTutelaSeverityData)
                changeByPortData(byPortData)
                changeByAddressData(byAddressData)
                changeByVersionData(byVersionData)
                changeByResolutionData(byResolutionData)    
                changeByExploitData(byExploitData)
                changeByDetectionTypeData(byDetectionTypeData)
                changeTotalItemCount(resp.data.Total)
            }            

            changeInitialSearchData({
                "Severity": bySeverityData,
                "TutelaSeverity": byTutelaSeverityData,
                "Port": byPortData,
                "Address": byAddressData,
                "Version": byVersionData,
                "CVE": byCveNameData,
                "Tag": byTagData,
                "EntityType": byDetectionTypeData
            })  

            changeDiscoveryData(resp.data.Cves)                        

            changeIsLoading(false)
        })        
    }

    useEffect(()=>{
        getDiscoveryData()
    }, [pageNumber, filters, sortDirection, sortField, pageSize])

    const tabs = [
       
        {
          id: 'network-host--id',
          name: 'Network & Hosts',
          prepend: <EuiIcon type="timeline"/>
        },
        /* hiding this for now
        {
            id: 'webscan--id',
            name: 'Webscans',
            prepend: <EuiIcon type="inspect"/>
        }
        */
        {
            id: 'compliance--id',
            name: 'Compliance Checks',
            prepend: <EuiIcon type="securitySignalResolved"/>
        },/*
        {
            id: 'library--id',
            name: 'Supply Chain',
            prepend: <EuiIcon type="layers"/>
        }
        */
        {
            id: 'entity--id',
            name: 'All Entities',
            prepend: <EuiIcon type="layers"/>
        },
    ]

    
    return <Fragment>
        <EuiPageHeader>
            <EuiPageHeaderSection>
                <EuiTitle size="l">
                    <h1>Discover</h1>
                </EuiTitle>
            </EuiPageHeaderSection>
            </EuiPageHeader>
            <EuiPageContent>
            <EuiTabs>
                {tabs.map((tab,index)=><EuiTab
                    key={index}
                    onClick={() => changeSelectedTabId(tab.id)}
                    isSelected={tab.id === selectedTabId}
                    prepend={tab.prepend}
                    append={tab.append}
                >
                    {tab.name}
                </EuiTab>)}
            </EuiTabs>
            {getPageContent(isLoading, totalItemCount, hasFilters(filters), selectedTabId)}         
        </EuiPageContent>        
        <FilterBar filters={filters} discardClickHandler={()=>changeFilters(DEFAULT_FILTERS)}/>        
    </Fragment>
}

export default Discover