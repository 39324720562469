import React, { useContext, useEffect, useState } from 'react'
import {
  EuiButton,
  EuiCard,
  EuiCodeBlock,
  EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiSpacer,
    EuiTab,
    EuiTabs,
    EuiText,
    EuiTitle,    
  } from '@elastic/eui';

import Axios from 'axios';
import Api from '../Api';
import { UserContext, ViewContext } from '../Context';
import WelcomeScreen from './WelcomeScreen';
import LandingDashboard from './LandingDashboard/LandingDashboard';



const LandingPage = () => {

  const {changeView} = useContext(ViewContext)
  const userContext = useContext(UserContext)

  const [currentTabView, changeCurrentTabView] = useState("WELCOME")
  const [showModal, changeShowModal] = useState(false)
  const [modalTitle, changeModalTitle] = useState("")
  const [modalContent, changeModalContent] = useState()

  

  const closeModal = () => { 
    changeShowModal(false)
  }

    
    useEffect(()=> {
      Axios.post(Api.checkLogin)
          .then(resp => {
              if (resp.status !== 200) {                    
                  changeView({
                      viewName: "LOGIN"
                  }) 
              }
              Axios.get(Api.checkAdmin).then(()=>{
                  userContext.changeIsAdminUser(true)
              })
          }).catch(e =>  changeView({
              viewName: "LOGIN"
          }))
  },[])
    

    
    return <>
            <EuiPageHeader>
            <EuiPageHeaderSection>
                <EuiTitle size="l">
                <h1>Welcome</h1>
                </EuiTitle>
            </EuiPageHeaderSection>
            </EuiPageHeader>
            <EuiPageContent>
                <EuiPageContentBody>    
                <EuiTabs>
                  <EuiTab isSelected={currentTabView === "WELCOME"} onClick={() => changeCurrentTabView('WELCOME')}>Home</EuiTab>
                  <EuiTab isSelected={currentTabView === "DASHBOARD"} onClick={() => changeCurrentTabView('DASHBOARD')}>Dashboard</EuiTab>
                </EuiTabs>
                <EuiSpacer />
                {currentTabView === "DASHBOARD" && <LandingDashboard />}
                {currentTabView === "WELCOME" && <>
                  <EuiFlexGroup gutterSize="l" wrap>
                  <EuiFlexItem>
                    <EuiCard
                      icon={<EuiIcon size="xl" type="starPlusFilled" />}
                      title="Getting started and support wiki"
                      display="plain"
                      hasBorder
                      description="It's recommended to start with the Getting Started Guide... and while you're there have a quick look at our support wiki"
                      onClick={() => {
                        //open a new tab 
                        window.open("https://outline.cybersift.io/s/support-wiki/doc/getting-started-rYVOmwKkuy")                        
                      }}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup gutterSize="l" wrap>
                  <EuiFlexItem>
                    <EuiCard
                      icon={<EuiIcon size="xl" type="discoverApp" />}
                      title="Detected Vulnerabilities"
                      display="plain"
                      hasBorder
                      description="Browse detected vulnerabilities, detected via either network or endpoint scans"
                      onClick={() => {
                        changeView({
                            viewName: "DISCOVER"
                        })   
                      }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiCard
                      icon={<EuiIcon size="xl" type="securityApp" />}
                      title="Detected Assets"
                      display="plain"
                      hasBorder
                      description="View All Detected Assets, via either network or endpoint scans"
                      onClick={() => {
                        changeView({
                          viewName: "ASSETS"
                        }) 
                      }}
                    />
                </EuiFlexItem> 
                  <EuiFlexItem>
                    <EuiCard
                      icon={<EuiIcon size="xl" type="reportingApp" />}
                      title="Vulnerabilities Report"
                      display="plain"
                      hasBorder
                      description="Download a CSV or PDF report of pending/resolved vulnerabilities"
                      onClick={() => {
                        changeView({
                          viewName: "PDF_REPORT"
                        }) 
                      }}
                    />
                  </EuiFlexItem> 
                  <EuiFlexItem>
                    <EuiCard
                      icon={<EuiIcon size="xl" type="securitySignalResolved" />}
                      title="Compliance Checks"
                      display="plain"
                      hasBorder
                      description="Browse security recommendations for your endpoints in order to confrom to security best practices (based on NIST 800-53 and ISO 27001:2022, requires agent installed)"
                      onClick={() => {
                        changeView({
                          viewName: "COMPLIANCE"
                        })  
                      }}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiCard
                      icon={<EuiIcon size="xl" type="./img/radarIDS.svg" />}
                      title="Network Scanner"
                      display="plain"
                      hasBorder
                      description="Download and install the network scanner"
                      onClick={() => {
                        changeModalTitle("Download and install the network scanner")   
                        changeModalContent(<>
                          <EuiText>
                            On a server with <a href="https://docs.docker.com/get-docker/" target="_blank">Docker</a> installed, run the following:
                          </EuiText>

                          <EuiSpacer size='s' />

                          <EuiCodeBlock language="bash" isCopyable>
                            docker pull dvassallocs/tutela
                          </EuiCodeBlock>

                          <EuiSpacer size='s' />

                          <EuiText>
                            Once installed, the scan can be setup via the <a href="#" onClick={()=> changeView({
                              viewName: "AGENTS"
                            })}>"Agent Management" screen</a> and run via the following:
                          </EuiText>

                          <EuiSpacer size='s' />

                          <EuiCodeBlock language="bash" isCopyable>
                            docker run dvassallocs/tutela --api_key INSERT_YOUR_API_KEY_HERE
                          </EuiCodeBlock>
                        </>)
                        changeShowModal(true)
                      }}
                    />
                  </EuiFlexItem> 
                  <EuiFlexItem>
                    <EuiCard
                      icon={<EuiIcon size="xl" type="./img/microsoft.svg" />}
                      title="Windows Agent"
                      display="plain"
                      hasBorder
                      description="Download the windows agent"
                      onClick={() => {
                              
                        changeModalTitle("Download and install the windows agent")   
                        changeModalContent(<>
                          <p>
                            The Windows MSI can be downloaded from:
                            <EuiSpacer size='m' />
                            <a 
                              target="_blank" 
                              href="https://csinstallscripts.blob.core.windows.net/cs-vas/tutela.msi"
                            >
                              https://csinstallscripts.blob.core.windows.net/cs-vas/tutela.msi
                            </a>                               
                            <EuiSpacer size='m' />
                            The MSI may be installed manually or pushed via an appropriate Group Policy Object
                          </p>            
                          <p>
                            Running the agent is very straightforward. Simply run the below command from an elevated privilege command prompt:
                            <EuiSpacer size='s' />
                            <EuiCodeBlock
                              style={{marginTop: 10, marginBottom: 10}}
                              language="bash"
                              fontSize="m"
                              paddingSize="m"
                              isCopyable>
                                  {'C:\\Program Files (x86)\\Tutela Windows Agent\\winAgent.exe --api_key INSERT_YOUR_API_KEY'}
                            </EuiCodeBlock> 
                          </p>
                          <EuiSpacer size='s' />
                          <p>
                            Scheduling the agent to run periodically can be easily accomplished using a windows scheduled task setup manually or protected via an
                            appropriate Group Policy Object
                          </p>
                        </>)
                        changeShowModal(true)
                        
                      }}
                    />
                  </EuiFlexItem>                   
                  <EuiFlexItem>
                    <EuiCard
                      icon={<EuiIcon size="xl" type="./img/linux.svg" />}
                      title="Linux Agent"
                      display="plain"
                      hasBorder
                      description="Download the Linux agent"
                      onClick={() => {
                        changeModalTitle("Download and install the linux agent")   
                        changeModalContent(<>
                          <p>
                            The linux agent is supplied as single binary and can be downloaded from here:
                            <EuiSpacer size='m' />
                            <a 
                              target="_blank" 
                              href="https://csinstallscripts.blob.core.windows.net/cs-vas/TutelaLinuxAgent"
                            >
                              https://csinstallscripts.blob.core.windows.net/cs-vas/TutelaLinuxAgent
                            </a>                            
                          </p>            
                          <EuiSpacer size='m' />
                          <p>
                            Running the agent is very straightforward. Simply run the below command from a root terminal:
                            <EuiCodeBlock
                              style={{marginTop: 10, marginBottom: 10}}
                              language="bash"
                              fontSize="m"
                              paddingSize="m"
                              isCopyable>
                                  {'./TutelaLinuxAgent -api_key INSERT_YOUR_API_KEY'}
                            </EuiCodeBlock> 
                          </p>
                          <EuiSpacer size='s' />
                          <p>            
                            The agent does not need to be "installed", simply executed, and can be run on a schedule using CRON. An example provisioning bash script which
                            downloads, and schedules the scan is provided below:
                            <EuiSpacer size='s' />
                            <EuiCodeBlock
                              style={{marginTop: 10, marginBottom: 10}}
                              language="bash"
                              fontSize="m"
                              paddingSize="m"
                              isCopyable>
                                  {`#!/bin/bash

export HTTP_PROXY=http://change-or-remove.proxy.com
export HTTPS_PROXY=http://change-or-remove.proxy.com

cd /usr/local/bin
wget https://csinstallscripts.blob.core.windows.net/cs-vas/TutelaLinuxAgent
chmod +x TutelaLinuxAgent

echo '''#!/bin/bash 
/usr/local/bin/TutelaLinuxAgent -api_key INSERT_YOUR_API_KEY''' > /etc/cron.monthly/tutela.sh
chmod +x /etc/cron.monthly/tutela.sh
`}
                            </EuiCodeBlock> 
                          </p>
                        </>)
                        changeShowModal(true)
                      }}
                    />
                  </EuiFlexItem> 
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiCard
                      icon={<EuiIcon size="xl" type="branch" />}
                      title="URL Scan"
                      display="plain"
                      hasBorder
                      description="Scan a URL to build a baseline of behaviour such as what requests are made, where scripts and images are loaded from, and more."
                      onClick={() => {changeView({
                        viewName: "URL_SCAN"
                    })  }}
                    />
                  </EuiFlexItem> 
                  <EuiFlexItem>
                    <EuiCard
                      icon={<EuiIcon size="xl" type="watchesApp" />}
                      title="Website Clone Detection"
                      display="plain"
                      hasBorder
                      description="Add various 'tripwires' to your site - allowing you to detect site cloning, whichtypically leads to phising attempts"
                      onClick={() => {
                        changeView({
                            viewName: "DOMAINS"
                        })
                      }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiCard
                      icon={<EuiIcon size="xl" type="classificationJob" />}
                      title="Monitor Data Leaks and Phishing Sites"
                      display="plain"
                      hasBorder
                      description="Add domains and keywords to be monitored for in various dark web, data leak forums, 'have I been pawnd' and other sites"
                      onClick={() => {
                        changeView({
                          viewName: "DOMAINS"
                        })  
                      }}
                    />
                  </EuiFlexItem>            
                </EuiFlexGroup>
                
                </>}
                </EuiPageContentBody>                            
            </EuiPageContent>        
        <WelcomeScreen />     
        { showModal ? <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>{modalTitle}</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            { modalContent }            
          </EuiModalBody>

          <EuiModalFooter>
          <EuiButton
            onClick={closeModal} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>: null}
    </>
}

export default LandingPage